import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Chip, Typography } from "@mui/material";
import React from "react";
import { MIS_FILTERS_MAPPING, renderValueForMISFilters } from "./Interfaces";

export default function AppliedFiltersSection({
  appliedFilters,
  setShowMISFilterModal,
  setAppliedFilters,
  setIsFilterApplied,
}) {
  const entries = Object.entries(appliedFilters);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ minWidth: "250px", overflow: "scroll", border: "1px solid rgba(0, 0, 0, 0.12)" }}>
        <Box
          sx={{
            width: "fit-content",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: 0.5,
            padding: 1,
          }}
        >
          {entries.map(([key, value]) => (
            <Chip
              key={key}
              label={
                <Box component="span">
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {MIS_FILTERS_MAPPING[key]}:
                  </Typography>
                  <Typography component="span" sx={{ fontWeight: "normal", ml: 0.5 }}>
                    {renderValueForMISFilters(key, value)}
                  </Typography>
                </Box>
              }
              onDelete={() => {
                const newFilters = { ...appliedFilters };
                delete newFilters[key];
                setAppliedFilters(newFilters);
                if (Object.keys(newFilters).length === 0) {
                  setIsFilterApplied(false);
                }
              }}
              deleteIcon={<CloseIcon />}
              sx={{
                "& .MuiChip-label": { fontSize: 13, padding: 1 },
              }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="text"
          onClick={() => {
            setShowMISFilterModal(true);
          }}
        >
          Edit
        </Button>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            setAppliedFilters({});
            setIsFilterApplied(false);
          }}
        >
          Remove All
        </Button>
      </Box>
    </Box>
  );
}
