/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider } from "@mui/material";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useLocation } from "react-router-dom";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { PageStatus } from "../BalanceConfirmationPortal/BalanceConfirmationPortal";
import {
  BcCustomisationDetails,
  CommunicationDetails,
  MailBoxPropsBc,
} from "../BalanceConfirmationPortal/BalanceConfirmationPortalCommon";
import { BcPortalContext } from "../BalanceConfirmationPortal/BalanceConfirmationPortalContext";
import { NameAndLogo } from "../BalanceConfirmationPortal/FinalPage";
import LendingTopBanner from "../Common/LendingTopBanner";
import ErrorPage from "../MsmePartnerPortal/ErrorPage";
import { Header, LoadingPage, NavBar } from "../MsmePartnerPortal/PartnerPortal.common";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";
import FinalPage from "./FinalPage";
import InitialPage from "./InitialPage";

// export type PageStatus = "NotFilled" | "FinalPage";

const LedgerRequestPortal: React.FC = () => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const initialRaiseIssueDialogState = params.get("raiseIssue") === "1" ? true : false;

  const [emailData, setEmailData] = useState<MailBoxPropsBc>({} as MailBoxPropsBc);

  const [openDialog, setOpenDialog] = useState(initialRaiseIssueDialogState);

  const { pageStatus, setPageStatus, nameAndLogo, setNameAndLogo, setCustomization } = useContext(BcPortalContext);

  const [openPortalAccessDialog, setOpenPortalAccessDialog] = useState(false);

  const GetPartnerNameAndLogo = () => {
    useFetch<NameAndLogo>(API_ENDPOINTS.GET_PARTNER_NAME_AND_LOGO_LR.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_PARTNER_NAME_AND_LOGO_LR.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setNameAndLogo(res.data);
        clarity.setTag("LedgerRequestPortal", `Start`);
        window.clarity("event", "LedgerRequestPortal-Start");
        clarity.setTag("RU", `${res.data.name}`);
        clarity.setTag("RU-BP", `${res.data.name}-${res.data.businessPartnerName}`);
      },
    });
  };

  const getLedgerRequestDetailsStatus = () => {
    return useFetch<{ status: PageStatus }>(API_ENDPOINTS.GET_PORTAL_DETAILS_STATUS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_PORTAL_DETAILS_STATUS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setPageStatus(res.data.status || "NotFilled");
      },
    });
  };

  const getEmailDetails = () => {
    return useFetch<{ emailDetails: MailBoxPropsBc }>(API_ENDPOINTS.GET_EMAIL_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_EMAIL_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setEmailData(res.data.emailDetails);
      },
    });
  };

  // For Customisation Details
  const GetCustomization = () =>
    useFetch<CommunicationDetails>(API_ENDPOINTS.GET_COMMUNICATION_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_COMMUNICATION_DETAILS.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        console.log({ data: res.data });

        const details = res.data;
        const defaultCustomisation: BcCustomisationDetails = {
          isPdfRequired: null,
          isPdfOptional: null,
          isLedgerRequired: null,
          isOpenItemRequired: null,
          isPdfHidden: null,
          isLedgerHidden: null,
          isOpenItemHidden: null,
          isPartnerBalanceRequired: null,
          isPartnerBalanceHidden: null,
        };

        let customisationIfBalanceConfirmed: BcCustomisationDetails;
        let customisationIfBalanceNotConfirmed: BcCustomisationDetails;
        let customisationIfLedgerRequest: BcCustomisationDetails;

        if (details?.customisationIfConfirmed?.ledger) {
          customisationIfBalanceConfirmed = {
            isPdfRequired: details?.customisationIfConfirmed?.pdf === "yes",
            isPdfOptional: details?.customisationIfConfirmed?.pdf === "optional",
            isLedgerRequired: details?.customisationIfConfirmed?.ledger === "yes",
            isOpenItemRequired: details?.customisationIfConfirmed?.openItem === "yes",
            isPdfHidden: details?.customisationIfConfirmed?.pdf === "no",
            isLedgerHidden: details?.customisationIfConfirmed?.ledger === "no",
            isOpenItemHidden: details?.customisationIfConfirmed?.openItem === "no",
            isPartnerBalanceRequired: details?.customisationIfLedgerRequest?.partnerBalance === "yes",
            isPartnerBalanceHidden: details?.customisationIfLedgerRequest?.partnerBalance === "no",
          };
        }

        if (details?.customisationIfNotConfirmed?.ledger) {
          customisationIfBalanceNotConfirmed = {
            isPdfRequired: details?.customisationIfNotConfirmed?.pdf === "yes",
            isPdfOptional: details?.customisationIfNotConfirmed?.pdf === "optional",
            isLedgerRequired: details?.customisationIfNotConfirmed?.ledger === "yes",
            isOpenItemRequired: details?.customisationIfNotConfirmed?.openItem === "yes",
            isPdfHidden: details?.customisationIfNotConfirmed?.pdf === "no",
            isLedgerHidden: details?.customisationIfNotConfirmed?.ledger === "no",
            isOpenItemHidden: details?.customisationIfNotConfirmed?.openItem === "no",
            isPartnerBalanceRequired: details?.customisationIfLedgerRequest?.partnerBalance === "yes",
            isPartnerBalanceHidden: details?.customisationIfLedgerRequest?.partnerBalance === "no",
          };
        }

        if (details?.customisationIfLedgerRequest?.ledger) {
          customisationIfLedgerRequest = {
            isPdfRequired: false,
            isPdfOptional: false,
            isLedgerRequired: details?.customisationIfLedgerRequest?.ledger === "yes",
            isOpenItemRequired: details?.customisationIfLedgerRequest?.openItem === "yes",
            isPdfHidden: true,
            isLedgerHidden: details?.customisationIfLedgerRequest?.ledger === "no",
            isOpenItemHidden: details?.customisationIfLedgerRequest?.openItem === "no",
            isPartnerBalanceRequired: details?.customisationIfLedgerRequest?.partnerBalance === "yes",
            isPartnerBalanceHidden: details?.customisationIfLedgerRequest?.partnerBalance === "no",
          };
        }

        setCustomization({
          balanceConfirmed: customisationIfBalanceConfirmed || defaultCustomisation,
          balanceNotConfirmed: customisationIfBalanceNotConfirmed || defaultCustomisation,
          ledgerRequest: customisationIfLedgerRequest || defaultCustomisation,
        });
      },
    });

  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      preprod: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  useEffect(() => {
    GetPartnerNameAndLogo();
    getLedgerRequestDetailsStatus();
    getEmailDetails();
    GetCustomization();
  }, []);

  return (
    <>
      <ErrorPage>
        <Suspense fallback={<LoadingPage />}>
          {pageStatus === "FinalPage" && <LendingTopBanner />}
          <NavBar companyName={nameAndLogo.name} companyLogo={nameAndLogo.logo} />
          <Divider className={$.borderColor} />
          <Header headerText="Ledger Request" />
          <Divider className={$.borderColor} />
          {
            pageStatus === "NotFilled" ? (
              <InitialPage
                emailData={emailData}
                // submitLedgerRequest={submitLedgerRequest}
                // submittingRequest={submittingRequest}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                openPortalAccessDialog={openPortalAccessDialog}
                setOpenPortalAccessDialog={setOpenPortalAccessDialog}
              />
            ) : pageStatus === "FinalPage" ? (
              <FinalPage partnerName={nameAndLogo.name} openDialog={openDialog} setOpenDialog={setOpenDialog} />
            ) : null // <FinalPage partnerName={partnerName} />
          }
        </Suspense>
      </ErrorPage>
    </>
  );
};

export default LedgerRequestPortal;
