import { Autocomplete, Box, Button, ListItemText, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { fetchAllActors } from "src/slices/admin/userListSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import styles from "./ReconDownloadReports.module.scss";

const reportOptions = [
  { label: "Recon Summary Metadata", apiUrl: "GetReconSummaryMetadata" },
  { label: "Recon Time Flow", apiUrl: "GetReconTimeFlow" },
] as const;

export default function ReconDownloadReports() {
  const dispatch = useDispatch();
  const listAllActors = useSelector((state: any) => state.userList.list);
  const allActorsOptions = listAllActors.map((actor) => {
    return {
      actorId: actor.actorId,
      actorName: actor.actorName,
    };
  });

  const allActorsOptionsWithAll = [{ actorId: "all", actorName: "Select All" }, ...allActorsOptions];
  const [clientTypeOptions, setClientTypeOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({ actorId: "all", actorName: "Select All" });
  const [selectedReport, setSelectedReport] = useState<typeof reportOptions[number]>();
  const [selectedClientType, setSelectedClientType] = useState([]);
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchAllActors() as any); // Dispatch the fetchAllActors action creator to fetch the list of actors
  }, [dispatch]);

  useEffect(() => {
    getClientTypeOptions();
  }, []);

  const getClientTypeOptions = () => {
    useFetch(API_ENDPOINTS.GET_ALL_CLIENT_TYPE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ALL_CLIENT_TYPE.failureMessage,
      thenCallBack: (res) => {
        // setClientTypeOptions(res.data || []);
        setClientTypeOptions(res.data.data);
      },
    });
  };

  const DownloadReport = () => {
    setLoading(true);
    useFetch<{ workBookBase64: string }>(API_ENDPOINTS.DOWNLOAD_SELECTED_REPORT(selectedReport.apiUrl).url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_SELECTED_REPORT(selectedReport.apiUrl).failureMessage,
      showSuccessToast: true,
      data: {
        startDate: startDate ? moment(startDate).format("DD-MM-YYYY") : null,
        endDate: endDate ? moment(endDate).format("DD-MM-YYYY") : null,
        ruActorId: selectedCompany ? (selectedCompany.actorId !== "all" ? selectedCompany.actorId : null) : null,
        clientTypes: selectedClientType.length > 0 ? selectedClientType : null,
      },
      thenCallBack: (res) => {
        const { filename } = res.data;
        const excelData = Buffer.from(res.data.workBookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
        setLoading(false);
      },
      catchCallBack: () => {
        setLoading(false);
      },
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // Check if end date is more than 95 days ahead
    if (date) {
      const maxEndDate = new Date(date);
      maxEndDate.setDate(maxEndDate.getDate() + 95);
      if (endDate && endDate > maxEndDate) {
        setEndDate(null);
      }
    }
  };
  const handleEndDateChange = (date) => {
    // Check if end date is not before start date and not more than 95 days ahead
    if (date && startDate) {
      const maxEndDate = new Date(startDate);
      maxEndDate.setDate(maxEndDate.getDate() + 95);
      if (date < startDate || date > maxEndDate) {
        setEndDate(null);
        return;
      }
    }
    setEndDate(date);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>
      <Autocomplete
        multiple
        sx={{ width: 450, background: "#fff", borderRadius: "20px" }}
        options={clientTypeOptions}
        autoHighlight
        disabled={selectedCompany.actorId !== "all"}
        value={selectedClientType}
        onChange={(_event, newValue) => {
          setSelectedClientType(newValue);
        }}
        renderOption={(props, item) => (
          <li {...props} key={item}>
            <ListItemText>{item}</ListItemText>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Client Type"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      <Autocomplete
        sx={{ width: 450, background: "#fff", borderRadius: "20px" }}
        options={allActorsOptionsWithAll}
        autoHighlight
        disableClearable
        disabled={selectedClientType.length > 0}
        value={selectedCompany}
        getOptionLabel={(option: any) => `${option.actorName} (Actor ID: ${option.actorId})`}
        onChange={(_event, newValue) => {
          setSelectedCompany(newValue);
        }}
        renderOption={(props, option) => (
          <Box
            key={`${option.actorId}-${option.actorName}`}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            style={{ display: "block" }}
          >
            <div>{option.actorName}</div>
            <div>
              <span style={{ color: "#666666", fontSize: "12px" }}>Actor ID: {option.actorId}</span>
            </div>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="RU"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      <Autocomplete
        sx={{ width: 450, background: "#fff", borderRadius: "20px" }}
        options={["Select All"]}
        defaultValue="Select All"
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="BP"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      <Box sx={{ display: "flex", flexDirection: "row", width: "450px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={styles.datepicker_container}>
            <DesktopDatePicker
              label="Start Date"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className={styles.datepicker_container}>
            <DesktopDatePicker
              label="End Date"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              minDate={startDate}
              maxDate={new Date(new Date(startDate).setDate(startDate.getDate() + 95))}
            />
          </div>
        </LocalizationProvider>
      </Box>
      <Autocomplete
        sx={{ width: 450, background: "#fff", borderRadius: "20px" }}
        autoHighlight
        options={reportOptions}
        value={selectedReport}
        onChange={(_event, newValue) => {
          setSelectedReport(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Report"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      <Button
        variant="contained"
        className="theme_btn"
        onClick={DownloadReport}
        disabled={loading || selectedReport === null}
      >
        {<LoadingIcon loading={loading} />}
        Download
      </Button>
    </Box>
  );
}
