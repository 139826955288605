import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import "./ReconOutput.scss";

interface ColumnData {
  columnName: string;
  isMandatory?: boolean;
  miscellaneousText?: string;
}

interface AnnexuresSheetData {
  mismatch: {
    own: ColumnData[];
    partner: ColumnData[];
    nakad: ColumnData[];
  };
  unaccountedInOwn: {
    partner: ColumnData[];
    nakad: ColumnData[];
  };
  unaccountedInPartner: {
    own: ColumnData[];
    nakad: ColumnData[];
  };
}

const annexuresSheetDataSections = [
  {
    label: "Amount Mismatch",
    description: "Add columns to annexure tables of amount mismatches and Suggested TDS matches",
    key: "mismatch",
    types: ["own", "partner", "nakad"],
    columnHeader: ["Own", "BP", "Nakad"],
  },
  {
    label: "Entries unaccounted in Own",
    description: "Add columns to annexure tables showing entries which are unaccount in own ledger",
    key: "unaccountedInOwn",
    types: ["partner", "nakad"],
    columnHeader: ["BP", "Nakad"],
  },
  {
    label: "Entries unaccounted in BP",
    description: "Add columns to annexure tables showing entries which are unaccount in partner ledger",
    key: "unaccountedInPartner",
    types: ["own", "nakad"],
    columnHeader: ["Own", "Nakad"],
  },
];

const columnConfig = [
  { label: "Own Columns", stateKey: "ownColumn" },
  { label: "Partner Columns", stateKey: "partnerColumn" },
  { label: "NAKAD Columns", stateKey: "nakadColumn" },
];

export const defaultResultFile = [
  { label: "Reconciliation Summary + Annexures", stateKey: "reconciliationSummary" },
  { label: "Internal Summary + Own Vs Partner", stateKey: "internalSummary" },
  // { label: "Full Ledgers ->", stateKey: "fullLedgers" },
  { label: "Own Ledger", stateKey: "ownLedger" },
  { label: "Partner Ledger", stateKey: "partnerLedger" },
  { label: "Help", stateKey: "help" },
  { label: "Ledger Summary", stateKey: "ledgerSummary" },
  // { label: "QC (CS Users)", stateKey: "qcCsUsers" },
];

// Options for column types and names
const columnTypes = ["Own", "BP", "Custom"];
const columnNames = {
  Own: [
    "Other Unique Id",
    "Payment Id",
    "Dncn Id",
    "Posting Date",
    "Debit Amount",
    "Credit Amount",
    "Particulars",
    "Withholding Tax Amount",
    "Reconciliation Status",
    "Group",
    "Invoice Id For Dncn",
    "Document Number",
    "Clearing Document",
    "Clearing Date",
    "Sanitized Other Unique Id",
    "Sanitized Document Date",
    "Sanitized Identifier Code",
    "Sanitized Reference No",
    "Sanitized Payment Id",
    "Sanitized Dncn Id",
    "Sanitized Invoice Id For Dncn",
    "Sanitized Debit Amount",
    "Sanitized Particulars",
    "Sanitised Document Number",
    "Sanitised Clearing Document",
    "Source (Filename & Sheetname)",
  ],
  BP: [
    "Other Unique Id",
    "Payment Id",
    "Dncn Id",
    "Posting Date",
    "Debit Amount",
    "Credit Amount",
    "Particulars",
    "Withholding Tax Amount",
    "Reconciliation Status",
    "Group",
    "Invoice Id For Dncn",
    "Document Number",
    "Clearing Document",
    "Clearing Date",
    "Sanitized Other Unique Id",
    "Sanitized Document Date",
    "Sanitized Identifier Code",
    "Sanitized Reference No",
    "Sanitized Payment Id",
    "Sanitized Dncn Id",
    "Sanitized Invoice Id For Dncn",
    "Sanitized Debit Amount",
    "Sanitized Particulars",
    "Sanitised Document Number",
    "Sanitised Clearing Document",
    "Source (Filename & Sheetname)",
  ],
  // NAKAD: [
  //   "Other Unique Id",
  //   "Payment Id",
  //   "Dncn Id",
  //   "Posting Date",
  //   "Debit Amount",
  //   "Credit Amount",
  //   "Particulars",
  //   "Withholding Tax Amount",
  //   "Reconciliation Status",
  //   "Group",
  //   "Invoice Id For Dncn",
  //   "Document Number",
  //   "Clearing Document",
  //   "Clearing Date",
  //   "Sanitized Other Unique Id",
  //   "Sanitized Document Date",
  //   "Sanitized Identifier Code",
  //   "Sanitized Reference No",
  //   "Sanitized Payment Id",
  //   "Sanitized Dncn Id",
  //   "Sanitized Invoice Id For Dncn",
  //   "Sanitized Debit Amount",
  //   "Sanitized Particulars",
  //   "Sanitised Document Number",
  //   "Sanitised Clearing Document",
  //   "Source (Filename & Sheetname)",
  // ],
};
const annexuresSheetSelectColumnsList = {
  own: [
    "Document Number",
    "Document Type",
    "Document Date",
    "Amount",
    "Other Unique Id",
    "Reference Number",
    "Posting Date",
    "Payment Id",
    "Dncn Id",
    "Invoice Id For Dncn",
    "Particulars",
    "Clearing Document",
    "Clearing Date",
    "Sanitized Document Number",
    "Sanitized Clearing Document",
    "Sanitized Clearing Date",
    "Sanitized Document Type",
    "Sanitized Document Date",
    "Sanitized Other Unique Id",
    "Sanitized Reference Number",
    "Sanitized Posting Date",
    "Sanitized Payment Id",
    "Sanitized Dncn Id",
    "Sanitized Invoice Id For Dncn",
    "Sanitized Particulars",
    "Entry Type",
    "Group",
    "Entry Number",
    "Withholding Tax Amount",
    "Source (Filename & Sheetname)",
    "Miscellaneous",
  ],
  partner: [
    "Document Number",
    "Document Type",
    "Document Date",
    "Amount",
    "Other Unique Id",
    "Reference Number",
    "Posting Date",
    "Payment Id",
    "Dncn Id",
    "Invoice Id For Dncn",
    "Particulars",
    "Clearing Document",
    "Clearing Date",
    "Sanitized Document Number",
    "Sanitized Clearing Document",
    "Sanitized Clearing Date",
    "Sanitized Document Type",
    "Sanitized Document Date",
    "Sanitized Other Unique Id",
    "Sanitized Reference Number",
    "Sanitized Posting Date",
    "Sanitized Payment Id",
    "Sanitized Dncn Id",
    "Sanitized Invoice Id For Dncn",
    "Sanitized Particulars",
    "Entry Type",
    "Group",
    "Entry Number",
    "Withholding Tax Amount",
    "Source (Filename & Sheetname)",
    "Miscellaneous",
  ],
  nakad: [
    "Match Id",
    "Reconciliation Status",
    "Matching Logs",
    "Review Match",
    "Amount Difference",
    "Amount Difference %",
  ],
};

// Utility function to generate Excel-like column names starting from 'W'
const getExcelColumnName = (index) => {
  let columnName = "";
  index += 20; // Adjusting the index to start from 'U'

  while (index >= 0) {
    columnName = String.fromCharCode((index % 26) + 65) + columnName;
    index = Math.floor(index / 26) - 1;
  }

  return columnName;
};

const ReconOutput = () => {
  const [showLoadingIconUpdateColumnsColor, setShowLoadingIconUpdateColumnsColor] = useState(false);
  const [showLoadingIconSetCustomResult, setShowLoadingIconSetCustomResult] = useState(false);
  const [showLoadingIconAddColumnsInOwnVsBpSheet, setShowLoadingIconAddColumnsInOwnVsBpSheet] = useState(false);
  const [showLoadingIconAddColumnsInReconciliationSummary, setShowLoadingIconAddColumnsInReconciliationSummary] =
    useState(false);

  const [columns, setColumns] = useState({
    ownColumn: "",
    partnerColumn: "",
    nakadColumn: "",
  });

  // State to manage all sections in defaultResultFile dynamically
  const [defaultResultFileState, setDefaultResultFileState] = useState({
    reconciliationSummary: "",
    internalSummary: "",
    // fullLedgers: "",
    ownLedger: "",
    partnerLedger: "",
    help: "",
    ledgerSummary: "",
    qcCsUsers: "Show",
  });

  // State for dynamically added table rows, initially empty
  const [rowsOwnVsPartner, setRowsOwnVsPartner] = useState([]);

  // Reconciliation Summary
  const [reconciliationRows, setReconciliationRows] = useState([]);

  // Annexures section

  const [annexuresSheetData, setAnnexuresSheetData] = useState<AnnexuresSheetData>({
    mismatch: {
      own: [],
      partner: [],
      nakad: [],
    },
    unaccountedInOwn: {
      partner: [],
      nakad: [],
    },
    unaccountedInPartner: {
      own: [],
      nakad: [],
    },
  });
  const [showLoadingIconAddColumnsInAnnexuresSheetData, setShowLoadingIconAddColumnsInAnnexuresSheetData] =
    useState(false);
  const [reconciliationSummaryFormat, setReconciliationSummaryFormat] = useState("");
  const [showLoadingReconciliationSummaryFormat, setShowLoadingReconciliationSummaryFormat] = useState(false);

  const handleDragEnd = (result, sectionKey, typeKey) => {
    const { source, destination } = result;
    if (!destination) return;

    const updatedData = Array.from(annexuresSheetData[sectionKey][typeKey]);
    const [movedItem] = updatedData.splice(source.index, 1);
    updatedData.splice(destination.index, 0, movedItem);

    setAnnexuresSheetData((prevData) => ({
      ...prevData,
      [sectionKey]: {
        ...prevData[sectionKey],
        [typeKey]: updatedData,
      },
    }));
  };

  const handleAddRow = (section, type) => {
    setAnnexuresSheetData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [type]: [...prevData[section][type], { columnName: "", isMandatory: false }],
      },
    }));
  };

  const handleDeleteRow = (section, type, index) => {
    setAnnexuresSheetData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [type]: prevData[section][type].filter((_, i) => i !== index),
      },
    }));
  };

  // Function to add a new row with default values
  const addRow = () => {
    setRowsOwnVsPartner([
      ...rowsOwnVsPartner,
      {
        columnNo: getExcelColumnName(rowsOwnVsPartner.length), // Generate column names starting from 'W'
        columnType: "",
        columnName: "",
        formula: "",
      },
    ]);
  };

  // Handler to update row values based on input changes
  const handleRowChange = (index, key, value) => {
    setRowsOwnVsPartner((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index][key] = value;

      // Update the formula and reset column name when column type changes
      if (key === "columnType") {
        updatedRows[index].formula = value === "Custom" ? "" : "Not Applicable";
        updatedRows[index].columnName = "";
      }

      return updatedRows;
    });
  };

  // Handler to clear row values without deleting the row itself
  const clearRow = (index) => {
    setRowsOwnVsPartner((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { columnNo: updatedRows[index].columnNo, columnType: "", columnName: "", formula: "" };
      return updatedRows;
    });
  };

  const updateColumnsColor = () => {
    setShowLoadingIconUpdateColumnsColor(true);
    useFetch(API_ENDPOINTS.UPDATE_COLUMNS_COLOR.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_COLUMNS_COLOR.failureMessage,
      showSuccessToast: true,
      data: {
        OwnColumnsColorCode: columns.ownColumn,
        businessPartnerColumnsColorCode: columns.partnerColumn,
        nakadColumnsColorCode: columns.nakadColumn,
      },
      thenCallBack: () => {
        setShowLoadingIconUpdateColumnsColor(false);
      },
      catchCallBack: () => {
        setShowLoadingIconUpdateColumnsColor(false);
      },
    });
  };

  const setCustomResult = () => {
    // Check if all fields are either "Hide" or "Remove"
    const { ...allHiddenOrRemovedWithoutQCSheet } = defaultResultFileState;
    delete allHiddenOrRemovedWithoutQCSheet.qcCsUsers;
    const allHiddenOrRemoved = Object.values(allHiddenOrRemovedWithoutQCSheet).every(
      (value) => value === "Hide" || value === "Remove"
    );
    if (allHiddenOrRemoved) {
      toast.error(<CustomToast message={"No sheets selected"} />);
    } else {
      setShowLoadingIconSetCustomResult(true);
      useFetch(API_ENDPOINTS.SET_DEFAULT_DOWNLOAD_SETTINGS.url, "POST", {
        failureMessage: API_ENDPOINTS.SET_DEFAULT_DOWNLOAD_SETTINGS.failureMessage,
        showSuccessToast: true,
        data: {
          ReconciliationSummaryAndAnnexures: defaultResultFileState.reconciliationSummary,
          InternalSummaryAndOwnVsPartner: defaultResultFileState.internalSummary,
          // FullLedgers: defaultResultFileState.fullLedgers,
          OwnLedger: defaultResultFileState.ownLedger,
          PartnerLedger: defaultResultFileState.partnerLedger,
          Help: defaultResultFileState.help,
          LedgerSummary: defaultResultFileState.ledgerSummary,
          QcSheets: defaultResultFileState.qcCsUsers,
        },
        thenCallBack: () => {
          setShowLoadingIconSetCustomResult(false);
        },
        catchCallBack: () => {
          setShowLoadingIconSetCustomResult(false);
        },
      });
    }
  };

  const addColumnsInOwnVsBpSheet = () => {
    setShowLoadingIconAddColumnsInOwnVsBpSheet(true);
    useFetch(API_ENDPOINTS.ADD_COLUMNS_IN_OWN_VS_BP_SHEET.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_COLUMNS_IN_OWN_VS_BP_SHEET.failureMessage,
      showSuccessToast: true,
      data: { extraColumnsInOwnVsBpSheet: rowsOwnVsPartner },
      thenCallBack: () => {
        setShowLoadingIconAddColumnsInOwnVsBpSheet(false);
      },
      catchCallBack: () => {
        setShowLoadingIconAddColumnsInOwnVsBpSheet(false);
      },
    });
  };
  const addColumnsInReconciliationSummary = () => {
    setShowLoadingIconAddColumnsInReconciliationSummary(true);
    useFetch(API_ENDPOINTS.ADD_COLUMNS_IN_RECONCILIATION_SUMMARY.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_COLUMNS_IN_RECONCILIATION_SUMMARY.failureMessage,
      showSuccessToast: true,
      data: { extraColumnsInReconciliationSummary: reconciliationRows },
      thenCallBack: () => {
        setShowLoadingIconAddColumnsInReconciliationSummary(false);
      },
      catchCallBack: () => {
        setShowLoadingIconAddColumnsInReconciliationSummary(false);
      },
    });
  };
  const addColumnsInAnnexuresSheet = () => {
    setShowLoadingIconAddColumnsInAnnexuresSheetData(true);
    useFetch(API_ENDPOINTS.ADD_COLUMNS_IN_ANNEXURES_SHEET.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_COLUMNS_IN_ANNEXURES_SHEET.failureMessage,
      showSuccessToast: true,
      data: { extraColumnsInAnnexuresSheet: annexuresSheetData },
      thenCallBack: () => {
        setShowLoadingIconAddColumnsInAnnexuresSheetData(false);
      },
      catchCallBack: () => {
        setShowLoadingIconAddColumnsInAnnexuresSheetData(false);
      },
    });
  };

  useEffect(() => {
    getAnnexuresOrder();
    getColumnsColor();
    getExtraColumnsInExcelSheet();
    getDefaultDownloadSettings();
  }, []);

  const getColumnsColor = () => {
    useFetch(API_ENDPOINTS.GET_COLUMNS_COLOR.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_COLUMNS_COLOR.failureMessage,
      thenCallBack: (response) => {
        setColumns({
          ownColumn: response.data.data.ownColumnsColor || "",
          partnerColumn: response.data.data.businessPartnerColumnsColor || "",
          nakadColumn: response.data.data.nakadColumnsColor || "",
        });
      },
    });
  };
  const getDefaultDownloadSettings = () => {
    useFetch(API_ENDPOINTS.GET_DEFAULT_DOWNLOAD_SETTINGS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DEFAULT_DOWNLOAD_SETTINGS.failureMessage,
      thenCallBack: (response) => {
        setDefaultResultFileState({
          reconciliationSummary: response.data.data.ReconciliationSummaryAndAnnexures || "",
          internalSummary: response.data.data.InternalSummaryAndOwnVsPartner || "",
          // fullLedgers: response.data.data.FullLedgers || "",
          ownLedger: response.data.data.OwnLedger || "",
          partnerLedger: response.data.data.PartnerLedger || "",
          help: response.data.data.Help || "",
          ledgerSummary: response.data.data.LedgerSummary || "",
          qcCsUsers: response.data.data.QcSheets || "Show",
        });
      },
    });
  };

  const getExtraColumnsInExcelSheet = () => {
    useFetch(API_ENDPOINTS.GET_EXTRA_COLUMNS_IN_EXCEL_SHEET.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_EXTRA_COLUMNS_IN_EXCEL_SHEET.failureMessage,
      thenCallBack: (response) => {
        setRowsOwnVsPartner(response.data.data?.ownVsBpSheet || []);
        setReconciliationRows(response.data.data?.reconciliationSummarySheet || []);
        setAnnexuresSheetData(
          response.data.data?.annexureSheet || {
            mismatch: {
              own: [],
              partner: [],
              nakad: [],
            },
            unaccountedInOwn: {
              partner: [],
              nakad: [],
            },
            unaccountedInPartner: {
              own: [],
              nakad: [],
            },
          }
        );
      },
    });
  };

  const handleInputChange = (event, stateKey) => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      [stateKey]: event.target.value,
    }));
  };

  // Handle changes for defaultResultFile dynamically
  const handleDropdownChange = (event, stateKey) => {
    setDefaultResultFileState((prevState) => ({
      ...prevState,
      [stateKey]: event.target.value,
    }));
  };

  //  Reconciliation Summary

  const addReconciliationRow = () => {
    setReconciliationRows([...reconciliationRows, { columnName: "" }]);
  };

  const deleteReconciliationRow = (index) => {
    const updatedRows = reconciliationRows.filter((_, idx) => idx !== index);
    setReconciliationRows(updatedRows);
  };

  const getAnnexuresOrder = () => {
    useFetch(API_ENDPOINTS.GET_ANNEXURES_ORDER.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ANNEXURES_ORDER.failureMessage,
      thenCallBack: (response) => {
        setReconciliationSummaryFormat(response.data.data.annexuresOrderType);
      },
    });
  };

  const updateAnnexuresOrder = () => {
    setShowLoadingReconciliationSummaryFormat(true);
    useFetch(API_ENDPOINTS.UPDATE_ANNEXURES_ORDER.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_ANNEXURES_ORDER.failureMessage,
      showSuccessToast: true,
      data: {
        annexuresOrderType: reconciliationSummaryFormat,
      },
      thenCallBack: () => {
        setShowLoadingReconciliationSummaryFormat(false);
      },
      catchCallBack: () => {
        setShowLoadingReconciliationSummaryFormat(false);
      },
    });
  };

  return (
    <div>
      {/* Reconciliation Summary Format */}
      <Grid>
        <Paper>
          <Grid className="vertical_center_align">
            <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
              Reconciliation Summary Format:
            </Typography>
            <Grid>
              <FormControl fullWidth className="update-custom-border-radius" sx={{ mr: 1, ml: 1 }}>
                <Select
                  style={{ width: "220px" }}
                  fullWidth
                  size="small"
                  value={reconciliationSummaryFormat}
                  onChange={(e) => setReconciliationSummaryFormat(e.target.value)}
                >
                  <MenuItem value="entryType">Format 1</MenuItem>
                  <MenuItem value="actionOwner">Format 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid>
              <Button
                className={showLoadingReconciliationSummaryFormat ? "grey_btn" : "green_btn"}
                sx={{ width: "90px" }}
                onClick={updateAnnexuresOrder}
                startIcon={<LoadingIcon loading={showLoadingReconciliationSummaryFormat} />}
                disabled={showLoadingReconciliationSummaryFormat}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Customise Column Header Colors */}

      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Customise Column Header Colors
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid container spacing={2}>
              {columnConfig.map(({ label, stateKey }) => (
                <Grid item container spacing={1} alignItems="center" key={stateKey}>
                  <Grid item style={{ width: "260px" }}>
                    <ListItem className="col_header_name">{label}</ListItem>
                  </Grid>
                  <Grid item style={{ width: "160px" }}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      className="update-custom-border-radius"
                      value={columns[stateKey]}
                      onChange={(e) => handleInputChange(e, stateKey)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconUpdateColumnsColor ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={updateColumnsColor}
              startIcon={<LoadingIcon loading={showLoadingIconUpdateColumnsColor} />}
              disabled={showLoadingIconUpdateColumnsColor}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>

      {/*   Default Result File */}

      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Default Result File
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid container>
              <Grid item container alignItems="center" sx={{ mb: 2, background: "#F3F3F3" }}>
                <Grid item xs={10.5}>
                  <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                    Sheet Name
                  </ListItem>
                </Grid>
                <Grid item xs={1.5}>
                  <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                    Action
                  </ListItem>
                </Grid>
              </Grid>
              {defaultResultFile.map(({ label, stateKey }) => (
                <Grid item container alignItems="center" key={stateKey} sx={{ pb: 1 }}>
                  <Grid item xs={10.5}>
                    <ListItem className="col_header_name">{label}</ListItem>
                  </Grid>
                  <Grid item xs={1.5}>
                    <FormControl fullWidth className="update-custom-border-radius" sx={{ pr: 2 }}>
                      <Select
                        fullWidth
                        size="small"
                        value={defaultResultFileState[stateKey]}
                        onChange={(e) => handleDropdownChange(e, stateKey)}
                      >
                        <MenuItem value="Show">Show</MenuItem>
                        <MenuItem value="Hide">Hide</MenuItem>
                        <MenuItem value="Remove">Remove</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconSetCustomResult ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={setCustomResult}
              startIcon={<LoadingIcon loading={showLoadingIconSetCustomResult} />}
              disabled={showLoadingIconSetCustomResult}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>

      {/*    Own Vs Partner */}

      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Own Vs Partner
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid
              container
              spacing={0}
              sx={{ pl: 2, pr: 2, fontWeight: 600, mb: 1, background: "#F3F3F3", pt: "4px", pb: "4px" }}
              className="col_header_name"
            >
              <Grid item xs={1.5} sx={{ textAlign: "center", pr: 2 }}>
                Column No.
              </Grid>
              <Grid item xs={2} sx={{ pr: 2 }}>
                Column Type
              </Grid>
              <Grid item xs={3} sx={{ pr: 2 }}>
                Column Name
              </Grid>
              <Grid item xs={4.5}>
                Formula
              </Grid>
              <Grid item xs={1} sx={{ textAlign: "center" }}>
                Action
              </Grid>
            </Grid>

            {/* Data Rows */}
            {rowsOwnVsPartner.map((row, index) => (
              <Grid
                container
                alignItems="center"
                key={index}
                sx={{
                  // mt: 1,
                  pt: 1,
                  pb: 1,
                  pl: 2,
                  pr: 2,
                  // backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'transparent',
                }}
              >
                <Grid item xs={1.5} sx={{ textAlign: "center", pr: 2 }}>
                  {row.columnNo}
                </Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                  <Select
                    fullWidth
                    size="small"
                    value={row.columnType}
                    onChange={(e) => handleRowChange(index, "columnType", e.target.value)}
                    displayEmpty
                    style={{ borderRadius: "4px" }}
                  >
                    <MenuItem value="" disabled>
                      Select Column Type
                    </MenuItem>
                    {columnTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3} sx={{ pr: 2 }}>
                  {row.columnType === "Custom" ? (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.columnName}
                      onChange={(e) => handleRowChange(index, "columnName", e.target.value)}
                      className="update-custom-border-radius"
                    />
                  ) : (
                    <Autocomplete
                      className="update-custom-border-radius"
                      value={row.columnName || null} // Use null if no value is selected
                      onChange={(_event, newValue) => {
                        if (typeof newValue === "string") {
                          handleRowChange(index, "columnName", newValue); // Set to the string input
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          handleRowChange(index, "columnName", newValue.inputValue);
                        } else {
                          handleRowChange(index, "columnName", newValue);
                        }
                      }}
                      filterOptions={(options, { inputValue }) => {
                        const filtered = options.filter((option) =>
                          option.toLowerCase().includes(inputValue.toLowerCase())
                        );

                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue.toLowerCase() === option.toLowerCase());
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue, // Directly use inputValue without title
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={columnNames[row.columnType] || []} // Use your existing options
                      getOptionLabel={(option) => {
                        return option.inputValue || option; // Handle inputValue for new suggestions
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option.inputValue || option}{" "}
                          {/* Display inputValue for new suggestions, or the option itself */}
                        </li>
                      )}
                      freeSolo // Allow custom input
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Column Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{ borderRadius: "4px" }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={4.5}>
                  {row.columnType === "Custom" ? (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.formula}
                      onChange={(e) => handleRowChange(index, "formula", e.target.value)}
                      className="update-custom-border-radius"
                    />
                  ) : (
                    <Typography className="NA_text">Not Applicable</Typography>
                  )}
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                  <IconButton onClick={() => clearRow(index)}>
                    <DeleteIcon sx={{ color: "#D32F2F" }} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid sx={{ p: 2 }}>
              <Button sx={{ mt: 2 }} onClick={addRow} startIcon={<AddIcon />}>
                Add New Column
              </Button>
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconAddColumnsInOwnVsBpSheet ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={addColumnsInOwnVsBpSheet}
              startIcon={<LoadingIcon loading={showLoadingIconAddColumnsInOwnVsBpSheet} />}
              disabled={showLoadingIconAddColumnsInOwnVsBpSheet}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>

      {/*  Reconciliation Summary */}

      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Reconciliation Summary
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid container>
              <Grid item container alignItems="center" sx={{ mb: 2, background: "#F3F3F3" }}>
                <Grid item xs={10.5}>
                  <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                    Column Name
                  </ListItem>
                </Grid>
                <Grid item xs={1.5}>
                  <ListItem
                    className="col_header_name"
                    sx={{ pt: "4px", pb: "4px", fontWeight: 600, justifyContent: "center" }}
                  >
                    Action
                  </ListItem>
                </Grid>
              </Grid>
              {reconciliationRows.map((row, index) => (
                <Grid item container alignItems="center" key={index} sx={{ pb: 1 }}>
                  <Grid item xs={10.5}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.columnName}
                      onChange={(e) => {
                        const updatedRows = [...reconciliationRows];
                        updatedRows[index].columnName = e.target.value;
                        setReconciliationRows(updatedRows);
                      }}
                      sx={{ maxWidth: "400px", pl: 2 }}
                      className="update-custom-border-radius"
                    />
                  </Grid>
                  <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => deleteReconciliationRow(index)}>
                      <DeleteIcon sx={{ color: "#D32F2F" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid sx={{ p: 2 }}>
              <Button sx={{ mt: 2 }} onClick={addReconciliationRow} startIcon={<AddIcon />}>
                Add New Column
              </Button>
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconAddColumnsInReconciliationSummary ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={addColumnsInReconciliationSummary}
              startIcon={<LoadingIcon loading={showLoadingIconAddColumnsInReconciliationSummary} />}
              disabled={showLoadingIconAddColumnsInReconciliationSummary}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>

      {/*   Annexures */}
      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Annexures
          </Typography>

          <Grid container spacing={2}>
            {annexuresSheetDataSections.map((section) => (
              <Grid item xs={12} key={section.key}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    padding: "8px 16px",
                    backgroundColor: "#f3f3f3",
                    borderBottom: "1px solid #DADADA",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "16px", color: "#000000de" }}>
                    {section.label}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 400, fontSize: "14px", color: "#00000099" }}>
                    {section.description}
                  </Typography>
                </Box>

                <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
                  <Grid container>
                    <Grid item container alignItems="center" sx={{ mb: 2, background: "#F3F3F3" }}>
                      {section.columnHeader.map((columnHeaderName) => (
                        <Grid item xs={section.types?.length === 2 ? 6 : 4} key={columnHeaderName}>
                          <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", pl: 6, fontWeight: 600 }}>
                            {columnHeaderName}
                          </ListItem>
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item container sx={{ pb: 1 }}>
                      {section.types.map((type) => (
                        <Grid item xs={section.types?.length === 2 ? 6 : 4} key={type}>
                          <DragDropContext onDragEnd={(result) => handleDragEnd(result, section.key, type)}>
                            <Droppable droppableId={`${section.key}-${type}`}>
                              {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  {annexuresSheetData[section.key][type].map((row, index) => (
                                    <Draggable key={`${type}-${index}`} draggableId={`${type}-${index}`} index={index}>
                                      {(provided) => (
                                        <Grid
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          sx={{ mb: 1.5, pl: 1, pr: 1 }}
                                        >
                                          <Grid className="d_flex">
                                            <IconButton
                                              sx={{
                                                cursor: "grab", // Set grab cursor
                                              }}
                                            >
                                              <DragHandleIcon />
                                            </IconButton>

                                            <Autocomplete
                                              className="update-custom-border-radius"
                                              sx={{ marginRight: row?.isMandatory === true ? "40px" : "" }}
                                              disabled={row?.isMandatory === true}
                                              fullWidth
                                              size="small"
                                              options={annexuresSheetSelectColumnsList[type]} // Adjust options as needed
                                              value={row.columnName || null}
                                              onChange={(_e, newValue) => {
                                                const updatedData = [...annexuresSheetData[section.key][type]];
                                                updatedData[index].columnName = newValue || "";
                                                setAnnexuresSheetData((prevData) => ({
                                                  ...prevData,
                                                  [section.key]: { ...prevData[section.key], [type]: updatedData },
                                                }));
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Select Column"
                                                  variant="outlined"
                                                  size="small"
                                                />
                                              )}
                                            />
                                            {row?.isMandatory !== true && (
                                              <IconButton onClick={() => handleDeleteRow(section.key, type, index)}>
                                                <DeleteIcon sx={{ color: "#D32F2F" }} />
                                              </IconButton>
                                            )}
                                          </Grid>

                                          {row.columnName === "Miscellaneous" && (
                                            <Grid sx={{ margin: "8px 40px 0px 40px" }}>
                                              <TextField
                                                className="update-custom-border-radius"
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                value={row?.miscellaneousText || ""}
                                                onChange={(e) => {
                                                  const updatedData = [...annexuresSheetData[section.key][type]];
                                                  updatedData[index].miscellaneousText = e.target.value;
                                                  setAnnexuresSheetData((prevData) => ({
                                                    ...prevData,
                                                    [section.key]: { ...prevData[section.key], [type]: updatedData },
                                                  }));
                                                }}
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                  <Button
                                    sx={{ mt: 1.5, pl: 6 }}
                                    onClick={() => handleAddRow(section.key, type)}
                                    startIcon={<AddIcon />}
                                  >
                                    Add New Column
                                  </Button>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconAddColumnsInAnnexuresSheetData ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={addColumnsInAnnexuresSheet}
              startIcon={<LoadingIcon loading={showLoadingIconAddColumnsInAnnexuresSheetData} />}
              disabled={showLoadingIconAddColumnsInAnnexuresSheetData}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default ReconOutput;
