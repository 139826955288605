import { MoreVert } from "@mui/icons-material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box, Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import ReactTable from "src/Components/ReactTable/ReactTable";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { User } from "../../PartnerCommunication/BalanceConfirmationBeta/MoveTaskToReconModal";
import { TabPanel } from "../../ReconSettings/ReconSettings";
import AddFunnelReport from "./AddFunnelReport";
import AddFunnelReportLoading from "./AddFunnelReportLoading";
import AddNewReportModal from "./AddNewReportModal";
import AppliedFiltersBar from "./AppliedFiltersBar";
import ConfirmDeleteReportModal from "./ConfirmDeleteReportModal";
import Header from "./Header";
import {
  FUNNEL_REPORT_ALL_COLUMNS,
  ListAllReportResponse,
  MISFilters,
  MISReportDetails,
  REPORT_TYPES,
  VIEW_COLUMNS,
} from "./Interfaces";
import MISFilter from "./MISFilter";
import TabsHeader from "./TabsHeader";
import ViewReportCustomisationsModal from "./ViewReportCustomisationsModal";

export default function PartnerRiskManagement() {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();

  const [showViewReportCustomisationsModal, setShowViewReportCustomisationsModal] = useState<boolean>(false);
  const [showMISFilterModal, setShowMISFilterModal] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<MISFilters>({});
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [showAddNewReportModal, setShowAddNewReportModal] = useState<boolean>(false);
  const [previewReportDetails, setPreviewReportDetails] = useState<{
    id: number;
    reportType: REPORT_TYPES;
    reportAction: string;
  }>({
    id: null,
    reportType: null,
    reportAction: null,
  });
  const [tabValue, setTabValue] = useState(0);
  const [isLoadingReport, setIsLoadingReports] = useState<boolean>(false);
  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [allLabels, setAllLabels] = useState<string[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [allReports, setAllReports] = useState<MISReportDetails[]>([]);
  const currentReportDetails = useRef<MISReportDetails>(null);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState<boolean>(false);
  const [deleteReportDetails, setDeleteReportDetails] = useState<{ id: number; reportName: string }>(null);
  const [isDownloadingBulkReports, setIsDownloadingBulkReports] = useState<boolean>(false);
  const [anchorElOpts, setAnchorElOpts] = React.useState<null | HTMLElement>(null);
  const handleMenuCloseOpts = () => {
    setAnchorElOpts(null);
  };
  const currentReport = useRef<MISReportDetails>();

  const funnelReportColumns = useMemo(
    () => [
      {
        accessorKey: "metric",
        header: "Metric",
        size: 250,
        accessorFn: (row) => row.metric ?? "",
      },
      {
        accessorKey: "numberOfPartners",
        header: "Total Partners",
        size: 250,
        accessorFn: (row) => row.numberOfPartners ?? "",
      },
      {
        accessorKey: "partnersContacted",
        header: "Partners Contacted",
        size: 250,
        accessorFn: (row) => row.partnersContacted ?? "",
      },
      {
        accessorKey: "partnersResponded",
        header: "Partners Responded",
        size: 250,
        accessorFn: (row) => row.partnersResponded ?? "",
      },
      {
        accessorKey: "partnersConfirmedBalance",
        header: "Partner confirmed balance",
        size: 250,
        accessorFn: (row) => row.partnersConfirmedBalance ?? "",
      },
      {
        accessorKey: "partnersSharedLedger",
        header: "Partner Shared Ledger",
        size: 250,
        accessorFn: (row) => row.partnersSharedLedger ?? "",
      },
      {
        accessorKey: "reconDone",
        header: "Recon done",
        size: 250,
        accessorFn: (row) => row.reconDone ?? "",
      },
      {
        accessorKey: "CBDiff",
        header: "CB difference",
        size: 250,
        accessorFn: (row) => row.CBDiff ?? "",
      },
      {
        accessorKey: "ownBalance",
        header: "Own balance",
        size: 250,
        accessorFn: (row) => row.ownBalance ?? "",
      },
      {
        accessorKey: "partnerBalance",
        header: "Partner balance",
        size: 250,
        accessorFn: (row) => row.partnerBalance ?? "",
      },
      {
        accessorKey: "invoiceNotBookedByPartner",
        header: "Invoice Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.invoiceNotBookedByPartner ?? "",
      },
      {
        accessorKey: "invoiceNotBookedByOwn",
        header: "Invoice not booked by Own",
        size: 250,
        accessorFn: (row) => row.invoiceNotBookedByOwn ?? "",
      },
      {
        accessorKey: "invoiceMisMatch",
        header: "Invoice Mismatch",
        size: 250,
        accessorFn: (row) => row.invoiceMisMatch ?? "",
      },
      {
        accessorKey: "paymentNotBookedByPartner",
        header: "Payment Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.paymentNotBookedByPartner ?? "",
      },
      {
        accessorKey: "paymentNotBookedByOwn",
        header: "Payment not booked by Own",
        size: 250,
        accessorFn: (row) => row.paymentNotBookedByOwn ?? "",
      },
      {
        accessorKey: "paymentMisMatch",
        header: "Payment Mismatch",
        size: 250,
        accessorFn: (row) => row.paymentMisMatch ?? "",
      },

      {
        accessorKey: "dncnNotBookedByPartner",
        header: "DN/CN Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.dncnNotBookedByPartner ?? "",
      },
      {
        accessorKey: "dncnNotBookedByOwn",
        header: "DN/CN not booked by Own",
        size: 250,
        accessorFn: (row) => row.dncnNotBookedByOwn ?? "",
      },
      {
        accessorKey: "dncnMisMatch",
        header: "DN/CN Mismatch",
        size: 250,
        accessorFn: (row) => row.dncnMisMatch ?? "",
      },
      {
        accessorKey: "tdsNotBookedByPartner",
        header: "Payment Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.tdsNotBookedByPartner ?? "",
      },
      {
        accessorKey: "tdsNotBookedByOwn",
        header: "Payment not booked by Own",
        size: 250,
        accessorFn: (row) => row.tdsNotBookedByOwn ?? "",
      },
      // {
      //   accessorKey: "invoiceReconciled",
      //   header: "Invoice Reconciled",
      //   size: 250,
      //   accessorFn: (row) => row.invoiceReconciled ?? "",
      // },
      // {
      //   accessorKey: "paymentReconciled",
      //   header: "Payment Reconciled",
      //   size: 250,
      //   accessorFn: (row) => row.paymentReconciled ?? "",
      // },
      {
        accessorKey: "taskLabel",
        header: "Task Label",
        size: 250,
        accessorFn: (row) => row.taskLabel ?? "",
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 250,
        accessorFn: (row) => row.category ?? "",
      },
      {
        accessorKey: "approver",
        header: "Approver",
        size: 250,
        accessorFn: (row) => row.approver ?? "",
      },
      {
        accessorKey: "activityPeriod",
        header: "Activity Period",
        size: 250,
        accessorFn: (row) => row.activityPeriod ?? "",
      },
      {
        accessorKey: "company",
        header: "Company",
        size: 250,
        accessorFn: (row) => row.company ?? "",
      },
      {
        accessorKey: "branch",
        header: "Branch",
        size: 250,
        accessorFn: (row) => row.branch ?? "",
      },
      {
        accessorKey: "relationship",
        header: "Relationship",
        size: 250,
        accessorFn: (row) => row.relationship ?? "",
      },
    ],
    []
  );

  const listAllReports = () => {
    setIsLoadingReports(true);
    useFetch<ListAllReportResponse>(API_ENDPOINTS.LIST_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.LIST_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.failureMessage,
      data: {
        reportType: REPORT_TYPES.Funnel,
        filters: appliedFilters,
      },
      thenCallBack: (response) => {
        setAllCategories(response.data.categories || []);
        setAllLabels(response.data.labels || []);
        setAllUsers(response.data.users || []);
        setAllReports(response.data.reports || []);
        setIsLoadingReports(false);
      },
      catchCallBack: () => {
        setIsLoadingReports(false);
      },
    });
  };

  const handleDownloadReport = (reportId) => {
    useFetch(API_ENDPOINTS.DOWNLOAD_REPORT_PARTNER_RISK_MANAGEMENT.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_REPORT_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      config: {
        params: {
          reportId,
        },
      },
      thenCallBack: (response) => {
        const { buffer, filename } = response.data;
        const excelData = Buffer.from(buffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
      },
    });
  };

  const downloadAllReports = () => {
    setIsDownloadingBulkReports(true);
    useFetch(API_ENDPOINTS.DOWNLOAD_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_ALL_REPORTS_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      data: {
        reportType: REPORT_TYPES.Funnel,
        filters: appliedFilters,
      },
      thenCallBack: (response) => {
        const { buffer, filename } = response.data;
        const excelData = Buffer.from(buffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
        setIsDownloadingBulkReports(false);
      },
      catchCallBack: () => {
        setIsDownloadingBulkReports(false);
      },
    });
  };

  useEffect(() => {
    listAllReports();
  }, [appliedFilters]);

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      {previewReportDetails.reportType ? (
        <AddFunnelReport
          allLabels={allLabels}
          allCategories={allCategories}
          allUsers={allUsers}
          setPreviewReportDetails={setPreviewReportDetails}
          previewReportDetails={previewReportDetails}
          listAllReports={listAllReports}
        />
      ) : (
        <>
          <Header setShowAddNewReportModal={setShowAddNewReportModal} setShowMISFilterModal={setShowMISFilterModal} />
          {isFilterApplied && (
            <AppliedFiltersBar
              appliedFilters={appliedFilters}
              setIsFilterApplied={setIsFilterApplied}
              setAppliedFilters={setAppliedFilters}
            />
          )}
          <TabsHeader tabValue={tabValue} setTabValue={setTabValue} />
          <Box sx={{ display: "flex", justifyContent: "end", marginTop: 1 }}>
            <Button
              disabled={isDownloadingBulkReports}
              startIcon={isDownloadingBulkReports ? <LoadingIcon loading={true} /> : <SaveAltIcon />}
              variant="text"
              onClick={() => downloadAllReports()}
            >
              Download all funnel reports
            </Button>
          </Box>
          {/* <TabPanel value={tabValue} index={0}></TabPanel> */}
          {isLoadingReport ? (
            <AddFunnelReportLoading />
          ) : (
            <TabPanel value={tabValue} index={0}>
              <Menu
                anchorEl={anchorElOpts}
                open={Boolean(anchorElOpts)}
                onClose={handleMenuCloseOpts}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  key={"downloadReport"}
                  onClick={() => {
                    handleDownloadReport(currentReport.current.id);
                    handleMenuCloseOpts();
                  }}
                >
                  Download Report
                </MenuItem>
                <MenuItem
                  key="editReport"
                  onClick={() => {
                    setPreviewReportDetails({
                      id: currentReport.current.id,
                      reportType: currentReport.current.reportType,
                      reportAction: "editReport",
                    });
                    handleMenuCloseOpts();
                  }}
                >
                  Edit Report
                </MenuItem>
                <MenuItem
                  key={"reportView"}
                  onClick={() => {
                    currentReportDetails.current = currentReport.current;
                    setShowViewReportCustomisationsModal(true);
                    handleMenuCloseOpts();
                  }}
                >
                  Report View and Filters
                </MenuItem>
                <MenuItem
                  key={"duplicateReport"}
                  onClick={() => {
                    setPreviewReportDetails({
                      id: currentReport.current.id,
                      reportType: currentReport.current.reportType,
                      reportAction: "duplicateReport",
                    });
                    handleMenuCloseOpts();
                  }}
                >
                  Duplicate Report
                </MenuItem>

                <MenuItem
                  key={"deleteReport"}
                  onClick={() => {
                    setDeleteReportDetails({
                      id: currentReport.current.id,
                      reportName: currentReport.current.reportName,
                    });
                    setShowDeleteReportModal(true);
                    handleMenuCloseOpts();
                  }}
                >
                  Delete Report
                </MenuItem>
              </Menu>

              {allReports.map((report) => {
                const rawCols = report.columnsOrder || [];
                const hiddenColumnsObj = [...FUNNEL_REPORT_ALL_COLUMNS, ...VIEW_COLUMNS]
                  .filter((item) => !rawCols.includes(item))
                  .reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                  }, {});
                return (
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "20px",
                      boxShadow: 5,
                      mb: 4,
                      overflow: "scroll",
                    }}
                    key={report.id}
                  >
                    <Stack direction="row" p={2}>
                      <Typography variant="h5" sx={{ fontWeight: 500, fontSize: "20px" }}>
                        {report.reportName}
                      </Typography>
                      <Button
                        endIcon={<MoreVert />}
                        sx={{ color: "rgba(0,0,0,0.6)", ml: "auto" }}
                        onClick={(ev) => {
                          currentReport.current = report;
                          setAnchorElOpts(ev.currentTarget);
                        }}
                      >
                        More Options
                      </Button>
                    </Stack>
                    <ReactTable
                      columns={funnelReportColumns}
                      rows={report.reportData}
                      enableRowSelection={false}
                      enableColumnFilters={false}
                      enableGrouping={false}
                      enableGlobalFilter={false}
                      enableColumnPinning={false}
                      enableExpanding={true}
                      enableColumnDragging={false}
                      enableExpandAll={true}
                      enableDensityToggle={false}
                      enableColumnOrdering={false}
                      internalActionsOrder={[]}
                      filterFromLeafRows={true}
                      initialStateColumnOrder={report.columnsOrder}
                      initialStateColumnVisibility={hiddenColumnsObj}
                    />
                  </Box>
                );
              })}
            </TabPanel>
          )}
        </>
      )}
      {showAddNewReportModal && (
        <AddNewReportModal
          open={showAddNewReportModal}
          setOpen={setShowAddNewReportModal}
          setPreviewReportDetails={setPreviewReportDetails}
        />
      )}
      {showViewReportCustomisationsModal && (
        <ViewReportCustomisationsModal
          open={showViewReportCustomisationsModal}
          setOpen={setShowViewReportCustomisationsModal}
          reportDetails={currentReportDetails.current}
          setPreviewReportDetails={setPreviewReportDetails}
        />
      )}
      {showMISFilterModal && (
        <MISFilter
          open={showMISFilterModal}
          setOpen={setShowMISFilterModal}
          allCategories={allCategories}
          allLabels={allLabels}
          allUsers={allUsers}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          setIsFilterApplied={setIsFilterApplied}
        />
      )}
      {showDeleteReportModal && (
        <ConfirmDeleteReportModal
          open={showDeleteReportModal}
          setOpen={setShowDeleteReportModal}
          reportName={deleteReportDetails.reportName}
          reportId={deleteReportDetails.id}
          listAllReports={listAllReports}
        />
      )}
    </LoggedInSkeleton>
  );
}
