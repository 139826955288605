import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";

export default function ConfirmDeleteReportModal({ open, setOpen, reportName, reportId, listAllReports }) {
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const handleDeleteReport = () => {
    setIsDeletingReport(true);
    useFetch(API_ENDPOINTS.DELETE_MIS_REPORT_PARTNER_RISK_MANAGEMENT.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_MIS_REPORT_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      config: {
        data: {
          id: reportId,
        },
      },
      thenCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
        listAllReports();
      },
      catchCallBack: () => {
        setIsDeletingReport(false);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to delete {reportName}?</DialogTitle>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingReport}
          variant="contained"
          color="error"
          sx={{ borderRadius: "4px !important" }}
          onClick={() => {
            handleDeleteReport();
          }}
        >
          {isDeletingReport && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
