
  /// <reference types="vite/client" />
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1;
      let clarityProjectIDs = {
        "production": "inqdavk56u",
        "preprod": "inqcy754wj",
        "staging": "inqcy754wj",
        "test": "inq7p42w47"
      };
      const env = "staging"; // vite env resolve
      t.src = "https://www.clarity.ms/tag/" + clarityProjectIDs[env];
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "");
  